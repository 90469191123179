type Type = 'WEIGHT' | 'CURRENCY';

export function format(type: Type, value: number): string {
  let result: string;

  switch (type) {
    case 'CURRENCY':
      result = new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);

      break;

    case 'WEIGHT':
      result = new Intl.NumberFormat('pt-br', {
        style: 'unit',
        unit: 'kilogram',
      }).format(value);

      break;

    default:
      break;
  }

  return result;
}
