import { api } from '..';

import { Indicator } from './types';

export const listIndicators = (): Promise<Indicator> => {
  return new Promise((resolve, reject) => {
    api
      .get('recycling/order/indicator/')
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};
