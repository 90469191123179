import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import Images from '../../config/theme/images';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { getFormError } from '../../util/formErrors';
import { errorMessage } from '../../util/messages';
import { Container, Content } from './styles';
import { SignInForrmData } from './types';

export const SignIn = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInForrmData) => {
      formRef.current?.setErrors({});
      try {
        const schema = Yup.object().shape({
          email: Yup.string().required('O E-mail é Obrigatório.'),
          password: Yup.string().required('A Senha é Obrigatória.'),
        });

        await schema.validate(data, { abortEarly: false });
        await signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getFormError(error);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um Error',
            //@ts-ignore
            description: errorMessage[error.message],
          });
        }
      }
    },
    [signIn, addToast]
  );

  return (
    <Container>
      <Content>
        <div className='isoLogoWrapper'>
          <img src={Images.logo} alt='Logo' style={{ height: 80 }} />
        </div>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name='email' placeholder='E-mail' />
          <Input name='password' type='password' placeholder='Senha' />
          <Button type='submit'>Entrar</Button>
        </Form>
      </Content>
    </Container>
  );
};
