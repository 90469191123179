import { api } from '../';

import { SignInRequest, SignInResponse } from './types';

export const signIn = (data: SignInRequest): Promise<SignInResponse> => {
  return new Promise((resolve, reject) => {
    api
      .post('api-token-auth/web/', data)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};
