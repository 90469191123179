import { api } from '../';

import { Terms } from './types';

export const listTerms = (): Promise<Terms> => {
  return new Promise((resolve, reject) => {
    api
      .get('settings/')
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};

export const editingTerms = (terms: Terms): Promise<Terms> => {
  return new Promise((resolve, reject) => {
    api
      .put('settings/', terms)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};
