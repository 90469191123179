import moment from 'moment';
moment.locale('pt-br');

export function formatDate(date: string, withTime?: boolean): string {
  return moment(date, !withTime ? 'DD-MM-YYYY' : 'h:mm DD-MM-YYYY').format(
    !withTime ? 'DD/MM/YYYY' : 'DD/MM/YYYY - hh:mm  A'
  );
}

export function dateToString(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}
