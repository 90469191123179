import styled from 'styled-components';

export const Container = styled.div``;
export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > div {
    margin-top: 0;
    margin-bottom: 20px;
    width: 33%;
  }
`;
