import { api } from '../';
import {
  RegisterWasteRequest,
  RegisterWasteResponse,
  ListWasteResponseProps,
  EditingWasteRequest,
  EditingWasteResponse,
} from './types';

export const registerWaste = (
  data: RegisterWasteRequest
): Promise<RegisterWasteResponse> => {
  return new Promise((resolve, reject) => {
    api
      .post('settings/waste-types/register/', data)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};

export const listWaste = (): Promise<ListWasteResponseProps[]> => {
  return new Promise((resolve, reject) => {
    api
      .get('settings/waste-types/')
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};

export const editingWaste = (
  hashcod: string,
  body: EditingWasteRequest
): Promise<EditingWasteResponse> => {
  return new Promise((resolve, reject) => {
    api
      .patch(`settings/waste-types/${hashcod}/`, { ...body })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response));
  });
};
