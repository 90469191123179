import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  hidden?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;

  & span {
    margin-top: 10px;
  }
  & + div {
    margin-top: 40px;
  }
  label {
    display: flex;
    align-self: flex-start;
    letter-spacing: 0.8px;
    margin-bottom: 7px;
    font-weight: bold;
  }
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}

  textarea {
    resize: none;
    min-height: 150px;
    width: 100%;
    /* flex: 1; */
    &::placeholder {
      color: transparent;
    }

    ${props =>
      props.isFocused &&
      css`
        border: 2px solid var(--blue-stone); ;
      `}

    ${props =>
      props.isErrored &&
      css`
        border-bottom: 2px solid var(--red);
      `}
  }

  ${({ isErrored }) =>
    isErrored &&
    css`
      & span {
        color: var(--red);
      }
    `}
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
  }
`;
