import React, { ButtonHTMLAttributes } from 'react';

import { Container, Loading } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  height?: number;
  marginLeft?: number;
  marginRight?: number;
  fontSize?: number;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  width,
  height,
  marginLeft,
  marginRight,
  fontSize,
  loading,
  ...rest
}) => {
  return (
    <Container
      width={width}
      height={height}
      marginLeft={marginLeft}
      marginRight={marginRight}
      fontSize={fontSize}
      disabled={loading}
      type='button'
      {...rest}
    >
      {loading ? <Loading /> : children}
    </Container>
  );
};
