import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Images from '../../config/theme/images';
import { Container, Content, ConfirmationText } from './styles';
import { useHistory } from 'react-router-dom';
import { UserConfirmationQueryParam } from './types';

import { confirmUserRegistration } from '../../services/UserConfirmationService';

export const UserConfirmation: React.FC = () => {
  const history = useHistory();
  const [confirmationText, setConfirmationText] = useState('');
  const { confirmation_hashcod } = useParams<UserConfirmationQueryParam>();

  const handleConfirmUserRegistration = useCallback(async () => {
    try {
      const response = await confirmUserRegistration(confirmation_hashcod);
      setConfirmationText('E-mail confirmado com sucesso.');
    } catch (error) {
      setConfirmationText('​​Não foi possível confirmar o seu e-mail.');
    }
  }, []);

  useEffect(() => {
    if (confirmation_hashcod) {
      handleConfirmUserRegistration();
    } else {
      setConfirmationText('​​Não foi possível confirmar o seu e-mail.');
    }
  }, []);

  return (
    <Container>
      <Content>
        <div className='isoLogoWrapper'>
          <img
            src={Images.logo}
            alt='Logo'
            style={{ height: 80 }}
            onClick={() => {
              history.push('/');
            }}
          />
        </div>
        <ConfirmationText>{confirmationText}</ConfirmationText>
      </Content>
    </Container>
  );
};
