import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { Container, Label } from './styles';

interface Option {
  id: string | number;
  label: string;
  disabled?: boolean;
}

interface Props {
  name: string;
  options: Option[];
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export const Radio: React.FC<InputProps> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        // const checked = refs.find((ref: any) => ref.checked);
        // return checked ? checked.value : null;
        return refs
          .filter((ref: any) => ref.checked)
          .map((ref: any) => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue(refs, values: any) {
        refs.forEach((ref: any) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });

        // const item = refs.find((ref: any) => ref.value === value);

        // if (item) {
        //   item.checked = true;
        // }
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const handleInputRef = useCallback((ref: any, index: number) => {
    inputRefs.current[index] = ref;
  }, []);

  return (
    <Container>
      {options.map((option, index) => (
        <Label key={option.label}>
          <input
            ref={ref => handleInputRef(ref, index)}
            type='checkbox'
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue?.includes(option.id)}
            disabled={option.disabled}
          />
          <span>{option.label}</span>
        </Label>
      ))}
    </Container>
  );
};
