import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* align-content: stretch; */
  height: 80px;
  padding: 0 30px;
  box-shadow: 1px 1px 2px var(--dove-gray);
  button {
    background: transparent;
    border: none;
  }
`;
export const HeaderItens = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 50px;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: normal;
    color: var(--dove-gray);
  }
`;
export const Profile = styled.label`
  font-size: 20px;
`;
