import { api } from '../index';

export const confirmUserRegistration = (confirm_hashcod: any): Promise<any> => {
  let uri = `user/register/confirm/${confirm_hashcod}/
  `;
  return new Promise((resolve, reject) => {
    api
      .get(uri, {
        transformRequest: (data, headers) => {
          delete headers?.Authorization;
          return data;
        },
      })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response.data));
  });
};
