import React, { useCallback, useEffect, useState } from 'react';
import { listIndicators } from '../../services/Indicators';
import { Indicator } from '../../services/Indicators/types';

import { MainContainer } from '../../components/MainContainer';
import { IndicatorItem, IndicatorInfo, IndicatorInfoContainer } from './styles';

export const Indicators: React.FC = () => {
  const [indicators, setIndicators] = useState<Indicator>({} as Indicator);
  const [loadingIndicators, setLoadingIndicators] = useState(true);

  const handleListIndicators = useCallback(async () => {
    try {
      const response = await listIndicators();
      setIndicators(response);
    } catch (error) {
    } finally {
      setLoadingIndicators(false);
    }
  }, []);

  const formatCurrency = (value: any) => {
    if (value === null || value === undefined) {
      return value;
    }
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  useEffect(() => {
    handleListIndicators();
  }, [handleListIndicators]);

  return (
    <MainContainer title='INDICADORES'>
      <IndicatorItem>
        <h2>Usuários</h2>

        <IndicatorInfoContainer>
          <IndicatorInfo>
            <label>CADASTRADOS</label>

            <span>
              {!loadingIndicators && Number(indicators.user_count_total)}
            </span>
          </IndicatorInfo>
        </IndicatorInfoContainer>
      </IndicatorItem>

      <IndicatorItem>
        <h2>COMPRAS PENDENTES</h2>

        <IndicatorInfoContainer>
          <IndicatorInfo>
            <label>TOTAL</label>

            <span>
              {!loadingIndicators &&
                Number(indicators.amount_of_pending_purchases)}
            </span>
          </IndicatorInfo>
        </IndicatorInfoContainer>
      </IndicatorItem>

      <IndicatorItem>
        <h2>COMPRAS REALIZADAS</h2>

        <IndicatorInfoContainer>
          <IndicatorInfo>
            <label>TOTAL</label>

            <span>
              {!loadingIndicators &&
                Number(indicators.amount_of_purchases_carried_out)}
            </span>
          </IndicatorInfo>
        </IndicatorInfoContainer>
      </IndicatorItem>

      <IndicatorItem>
        <h2>VALOR DE COMPRAS REALIZADAS</h2>

        <IndicatorInfoContainer>
          <IndicatorInfo>
            <label>TOTAL</label>

            <span>
              {!loadingIndicators &&
                formatCurrency(Number(indicators.total_purchase_value))}
            </span>
          </IndicatorInfo>

          <IndicatorInfo>
            <label>MÊS ATUAL</label>

            <span>
              {!loadingIndicators &&
                formatCurrency(
                  Number(indicators.total_purchase_value_current_month)
                )}
            </span>
          </IndicatorInfo>
        </IndicatorInfoContainer>
      </IndicatorItem>
    </MainContainer>
  );
};
