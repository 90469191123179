import { StatusOrder } from '../services/Order/types';

export function getStatusOrder(status: StatusOrder): string {
  const statusOrders = {
    ACCEPTED: 'ACEITO',
    PENDING: 'PENDENTE',
    REFUSED: 'RECUSADO',
    CANCELED: 'CANCELADO',
  };

  return statusOrders[status];
}
