import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
  button {
    width: 350px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  color: var(--prussian-blue);
`;

export const Table = styled.table`
  box-shadow: 2px 2px 1px (--prussian-blue);
  border-collapse: collapse;

  /* box-shadow: inset -8px 2px 8px -10px #015469,
    inset 0px -11px 8px -10px #015469, inset 11px 0px 8px -10px #015469; */

  thead {
    th {
      background: var(--blue-stone);
      height: 50px;
      color: var(--white);
      font-size: 15px;
      font-weight: normal;
      font-weight: normal;
      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
  tbody {
    tr {
      text-align: center;
      height: 50px;
      font-size: 17px;

      &:nth-child(even) td {
        /* background-color: rgba(var(--blue-stone), 0.1); */
        background: rgba(0, 84, 104, 0.1);
      }
    }
  }
`;

export const TableButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
`;

export const ContainerTable = styled.div`
  width: 100%;
`;
