import styled from 'styled-components';
import { VscLoading } from 'react-icons/vsc';
import { ButtonProps } from '.';

export const Container = styled.button<ButtonProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 51}px;

  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;

  font-size: ${({ fontSize }) => fontSize || 24}px;

  color: var(--white);

  background: var(--fern);

  border: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const Loading = styled(VscLoading)`
  animation: spin infinite 5s linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
