import React, {
  InputHTMLAttributes,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  hidden?: boolean;
}
export const Input: React.FC<InputProps> = ({ name, hidden, ...rest }) => {
  const inputRef = useRef(null);
  const { registerField, fieldName, defaultValue, error } = useField(name);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <Container hidden={hidden} isErrored={!!error} isFocused={isFocused}>
      <label>{rest.placeholder}</label>
      <input
        autoComplete='false'
        onFocus={handleFocus}
        onBlur={handleBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />
    </Container>
  );
};
