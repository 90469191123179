import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  hidden?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 60px;
  & + div {
    margin-top: 20px;
  }
  label {
    display: flex;
    align-self: flex-start;
    color: var(--dove-gray);
    letter-spacing: 0.8px;
    margin-bottom: 7px;
  }
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-bottom: 2px solid var(--blue-stone); ;
    `}
  ${props =>
    props.isErrored &&
    css`
      border-bottom: 2px solid var(--red); ;
    `}
  input {
    width: 100%;
    flex: 1;
    &::placeholder {
      color: transparent;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
  }
`;
