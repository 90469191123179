import React, { useState, useCallback } from 'react';
import { SideMenu } from '../SideMenu';
import { Header } from '../Header';

import { Container, ContentContainer, Content, HeaderContent } from './styles';
import { Button } from '../Button';

interface MainContainerProps {
  title: string;
  button?: {
    title: string;
    onClick: () => void;
  };
}

export const MainContainer: React.FC<MainContainerProps> = ({
  children,
  title,
  button,
}) => {
  const [openedSideMenu, setOpenedSideMenu] = useState(true);

  const handleSideMenu = useCallback(status => {
    setOpenedSideMenu(!status);
  }, []);
  return (
    <Container>
      <SideMenu opened={openedSideMenu} />

      <ContentContainer>
        <Header onClickMenu={() => handleSideMenu(openedSideMenu)} />

        <Content>
          <HeaderContent>
            <h1>{title}</h1>

            {button && (
              <Button type='button' onClick={() => button.onClick()}>
                {button.title}
              </Button>
            )}
          </HeaderContent>

          {children}
        </Content>
      </ContentContainer>
    </Container>
  );
};
