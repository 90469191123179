import React from 'react';
import { FiMenu } from 'react-icons/fi';
import { Container, HeaderItens, Profile } from './styles';
import { useAuth } from '../../context/AuthContext';

interface HeaderProps {
  onClickMenu: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onClickMenu }) => {
  const { signOut } = useAuth();

  return (
    <Container>
      <button onClick={onClickMenu} type='button'>
        <FiMenu size={30} />
      </button>
      <HeaderItens>
        <Profile>Olá, Administrador</Profile>
        <button onClick={() => signOut()} type='button'>
          Sair
        </button>
      </HeaderItens>
    </Container>
  );
};
