import React from 'react';

import { ToastMessage } from '../../context/ToastContext';
import { Content } from './Content';

import { Container } from './styles';

interface ToastProps {
  messages: ToastMessage[];
}

export const Toast: React.FC<ToastProps> = ({ messages }) => {
  return (
    <Container>
      {messages.map((message, index) => (
        <Content key={index} message={message} />
      ))}
    </Container>
  );
};
