import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--blue-stone);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 400px;
  height: 400px;
  border-radius: 20px;
  background: var(--white);
  .isoLogoWrapper {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    flex-shrink: 0;

    a {
      font-size: 24px;
      font-weight: 300;
      line-height: 1;
      text-transform: uppercase;

      img {
        display: flex;
        alignself: center;
      }
    }
  }
  form {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    padding: 0 20%;
    input {
      border: none;
      border-bottom: 1px solid var(--silver);
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    button {
      margin-top: 30px;
    }
  }
`;
