import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  padding: 50px;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin-top: 74px;
      align-self: center;
      width: 370px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    outline: none;
    background: transparent;
    margin-top: -15px;
    color: var(--fern);
  }
`;

export const CollectionDay = styled.div`
  div {
    margin-top: 12px;
  }
`;
