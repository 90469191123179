import styled from 'styled-components';

export const IndicatorItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  h2 {
    font-size: 19px;
    font-weight: 600;
    color: var(--prussian-blue);
    margin-bottom: 15px;
  }
`;

export const IndicatorInfoContainer = styled.div`
  display: flex;
`;

export const IndicatorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 150px;
  height: 75px;
  margin-right: 20px;
  background: var(--fern);
  box-shadow: 0 3px 6px var(--dove-gray);

  label {
    font-weight: 600;
  }
  label,
  span {
    font-size: 15px;
    color: var(--white);
  }
`;
