import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 35px;
  margin-right: 10px;
  color: var(--blue-stone);
  font-size: 20px;

  input[type='checkbox']:disabled + span {
    color: var(--dove-gray);
    &:hover {
      cursor: not-allowed;
    }
  }

  input[type='checkbox']:disabled {
    border: 1px solid var(--dove-gray);
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    outline: none;
    width: 100%;
    height: 100%;
    margin: 0;

    border: 1px solid var(--fern);
    &:hover {
      cursor: pointer;
    }
  }

  input[type='checkbox']:checked {
    background: var(--fern);
  }

  input[type='checkbox']:checked + span {
    color: var(--white);
  }

  span {
    position: absolute;
    &:hover {
      cursor: pointer;
    }
  }
`;
