import React, { useCallback } from 'react';
import { ModalBase, ModalBaseProps } from '../';
import { editingWaste } from '../../../services/Waste';
import { ListWasteResponseProps } from '../../../services/Waste/types';
import { Button } from '../../Button';
import { useToast } from '../../../context/ToastContext';
import { errorMessage } from '../../../util/messages';

interface WasteDeleteProps extends Omit<ModalBaseProps, 'title'> {
  waste: ListWasteResponseProps;
  hasDeleted: (hascode: string) => void;
}

export const WasteDelete: React.FC<WasteDeleteProps> = ({
  modalIsOpen,
  handleCloseModal,
  hasDeleted,
  waste,
}) => {
  const { addToast } = useToast();

  const handleDeleteWaste = useCallback(async () => {
    try {
      const { hashcod } = waste;

      await editingWaste(hashcod, { is_delete: true });
      addToast({ type: 'success', title: 'Sucesso' });
      hasDeleted(hashcod);
      handleCloseModal();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um Error',
        description: errorMessage.INVALID_CREDENTIALS,
      });
    }
  }, [addToast, handleCloseModal, hasDeleted, waste]);

  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      handleCloseModal={handleCloseModal}
      title='DESEJA CONTINUAR?'
    >
      <p>
        Deseja Excluir o item <strong>{waste.name}</strong>?
      </p>

      <Button onClick={handleDeleteWaste}>Confirmar</Button>
    </ModalBase>
  );
};
