import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

export function getFormError(error: ValidationError): Errors {
  const validationErrrors: Errors = {};

  error.inner.forEach(err => {
    validationErrrors[err.path!] = err.message;
  });

  return validationErrrors;
}
