import React from 'react';
import Modal from 'react-modal';

import { FiX } from 'react-icons/fi';

import { Container, Header } from './styles';

export interface ModalBaseProps {
  modalIsOpen: boolean;
  handleCloseModal: () => void;
  title: string;
}

export const ModalBase: React.FC<ModalBaseProps> = ({
  children,
  modalIsOpen,
  handleCloseModal,
  title,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      contentLabel='Example Modal'
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Container>
        <Header>
          <h2>{title}</h2>
          <button onClick={handleCloseModal}>
            <FiX size={30} />
          </button>
        </Header>
        {children}
      </Container>
    </Modal>
  );
};
