import styled, { css } from 'styled-components';

interface ContentProps {
  type: 'success' | 'info' | 'error';
}

const toastStyle = {
  info: css`
    background: var(--info-background);
    color: var(--info);
  `,
  success: css`
    background: var(--success-background);
    color: var(--success);
  `,
  error: css`
    background: var(--danger-background);
    color: var(--danger);
  `,
};
export const Container = styled.div<ContentProps>`
  display: flex;

  width: 300px;
  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px var(--prussian-blue);
  margin-bottom: 20px;

  ${props => toastStyle[props.type]}

  > svg {
    margin: 0 12px 0 0;
  }
  div {
    flex: 1;
    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 8px;
    opacity: 0.6;
    background: transparent;
    border: 0;
  }
`;
