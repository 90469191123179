import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root{
    --wild-sand: #F5F5F5;
    --blue-stone: #005468;
    --dove-gray: #707070;
    --white: #FFFFFF;
    --silver: #C5C5C5;
    --fern: #5EB876;
    --red: #FF0000;
    --prussian-blue:#002D51;
    --info-background:#cce5ff;
    --info:#004085;
    --success-background:#d4edda;
    --success:#155724;
    --danger-background:#f8d7da;
    --danger:#721c24;
  }

  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px 'Montserrat', sans-serif;
  }

  button{
    cursor: pointer;
  }

  [disabled]{
    opacity: 0.6;
    cursor: not-allowed;
  }

  .modal-overlay{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(112,112,112,0.39) ;
    
  }

.modal-content{
    width: 100%;
    max-width: 1077px;
    background: var(--white);
  }
`;
