import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 11rem;

  text-align: center;

  margin-bottom: 1.5rem;

  h2 {
    font-size: 1.125rem;
    font-weight: 600;

    margin-bottom: 0.875rem;

    color: var(--prussian-blue);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  width: 100%;

  height: 4.625rem;

  margin-right: 1.25rem;

  background: var(--fern);

  box-shadow: 0 3px 6px var(--dove-gray);

  label {
    font-weight: 600;
  }

  label,
  span {
    font-size: 1.125rem;

    color: var(--white);
  }
`;
