import React, { useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { getFormError } from '../../../util/formErrors';
import * as Yup from 'yup';

import { FiX } from 'react-icons/fi';
import { Input } from '../../Input';
import { Radio } from '../../Radio';
import { Button } from '../../Button';
import { useToast } from '../../../context/ToastContext';

import { registerWaste, editingWaste } from '../../../services/Waste';
import {
  Daysweek,
  ListWasteResponseProps,
} from '../../../services/Waste/types';

import { Container, Header, CollectionDay } from './styles';
import { errorMessage } from '../../../util/messages';

interface WasteRegistryProps {
  modalIsOpen: boolean;
  handleCloseModal: () => void;
  waste?: ListWasteResponseProps;
  isSuccessful: () => void;
}

interface WasteData {
  hashcod?: string;
  name: string;
  price_per_kilo: string;
  days_week: Array<Daysweek>;
}

const DAYS_OF_WEEK = [
  { id: 1, label: 'SEG' },
  { id: 2, label: 'TER' },
  { id: 3, label: 'QUA' },
  { id: 4, label: 'QUI' },
  { id: 5, label: 'SEX' },
  { id: 6, label: 'SÁB' },
  { id: 7, label: 'DOM', disabled: true },
];

const dayToId: any = {
  seg: 1,
  ter: 2,
  qua: 3,
  qui: 4,
  sex: 5,
  sáb: 6,
  dom: 7,
};

export const WasteRegistry: React.FC<WasteRegistryProps> = ({
  modalIsOpen,
  handleCloseModal,
  isSuccessful,
  waste,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: WasteData, { reset }) => {
      setLoading(true);
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required(),
          // price_per_kilo: Yup.number().required(),
        });
        await schema.validate(data, { abortEarly: false });

        !data.hashcod
          ? await registerWaste(data)
          : //@ts-ignore
            await editingWaste(data?.hashcod, data);

        addToast({
          type: 'success',
          title: 'Sucesso',
        });
        reset();
        handleCloseModal();
        isSuccessful();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getFormError(error);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um Error',
            description:
              error.status === 404
                ? errorMessage.NOT_FOUND
                : errorMessage.DEFAULT,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleCloseModal, isSuccessful]
  );

  const handleDaysWeekToId = useCallback((days_week: any) => {
    if (days_week)
      return days_week.map(
        (day: string) => dayToId[day.toLocaleLowerCase().substr(0, 3)]
      );
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      contentLabel='Example Modal'
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Container>
        <Header>
          <h2>CADASTRO DE RESÍDUO</h2>
          <button onClick={handleCloseModal}>
            <FiX size={30} />
          </button>
        </Header>

        <Form
          ref={formRef}
          initialData={{
            ...waste,
            days_week: handleDaysWeekToId(waste?.days_week),
          }}
          onSubmit={handleSubmit}
        >
          <Input hidden name='hashcod' />
          <Input name='name' placeholder='Nome do Resíduo:' />
          <Input
            type='number'
            name='price_per_kilo'
            placeholder='Peso (Kg):'
            step='0.01'
          />
          <CollectionDay>
            <label>DIA DA COLETA:</label>
            <Radio name='days_week' options={DAYS_OF_WEEK} />
          </CollectionDay>
          <Button loading={loading} type='submit'>
            {waste?.hashcod ? 'Editar' : 'Cadastrar'}
          </Button>
        </Form>
      </Container>
    </Modal>
  );
};
