import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isOpened: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 300px;

  background: var(--blue-stone);

  ${props =>
    !props.isOpened &&
    css`
      display: none;
    `}
`;

export const SideMenuContainerLogo = styled.div`
  display: flex;
  height: 80px;

  justify-content: center;
  align-items: center;
`;

export const SideMenuContainerNav = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 20px;
`;

export const SideMenuNav = styled(NavLink)`
  color: var(--white);
  font-size: 18px;
  padding-bottom: 20px;
  text-decoration: none;
  &.active {
    font-weight: bold;
    font-size: 20px;
  }
`;
