import { Form } from '@unform/web';
import React, { useCallback, useState } from 'react';
import { ModalBase, ModalBaseProps } from '../../';
import { useToast } from '../../../../context/ToastContext';
import { payOrder } from '../../../../services/Order';
import { Button } from '../../../Button';
import { Input } from '../../../Input';
import { Container, ContainerInputs } from './styles';

interface ReportShowProps extends Omit<ModalBaseProps, 'title'> {
  clientPaymentInfo: any;
}

export const ReportClientInfoPay: React.FC<ReportShowProps> = ({
  modalIsOpen,
  handleCloseModal,
  clientPaymentInfo,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmitPay = useCallback(async () => {
    setLoading(true);
    try {
      const response = await payOrder(clientPaymentInfo?.hashcod);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Pagamento Registrado.',
      });
      handleCloseModal();
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      if (error?.Error) {
        if (error?.Error === 'RECYCLING_ORDER_NOT_FOUND') {
          addToast({
            type: 'error',
            title:
              'Solcitiação não encontrada para efetuar o registro de pagamento!',
          });
          return;
        }

        if (error?.Error === 'RECYCLING_ORDER_PAID') {
          addToast({
            type: 'info',
            title: 'Esta solicitação já foi registrada como paga!',
          });
          return;
        }

        if (error?.Error === 'USER_BANK_ACCUONT_NOT_FOUND') {
          addToast({
            type: 'error',
            title: 'O usuário não possui PIX e nem conta bancária cadastrada!',
            description:
              'Só é possível registrar um pagamento caso e usuário possua um dos dois.',
          });
          return;
        }
      }

      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao registrar o pagamento desta solicitação.',
      });

      return error;
    }
  }, [clientPaymentInfo]);

  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      handleCloseModal={handleCloseModal}
      title='INFORMAÇÕES DE PAGAMENTO'
    >
      <Container>
        <Form onSubmit={handleSubmitPay} initialData={clientPaymentInfo}>
          <Input
            placeholder='Chave Pix'
            name='user_bank_info.pix_key'
            disabled
          />
          <Input
            placeholder='Nome Completo'
            name='user_hashcod.name'
            disabled
          />
          <Input placeholder='CPF' name='user_hashcod.cpf_cnpj' disabled />
          <ContainerInputs style={{ justifyContent: 'left', width: '100%' }}>
            <div style={{ marginRight: 10, width: '100%' }}>
              <Input
                placeholder='Banco'
                name='user_bank_info.bank_name'
                disabled
              />
            </div>
            <div style={{ width: '100%' }}>
              <Input
                placeholder='Tipo da Conta'
                name='user_bank_info.account_type'
                disabled
              />
            </div>
          </ContainerInputs>
          <ContainerInputs style={{ justifyContent: 'left', width: 500 }}>
            <Input
              placeholder='Agência'
              name='user_bank_info.agency_number'
              disabled
            />
            <div style={{ width: 50, marginInline: 10 }}>
              <Input
                width='50px'
                placeholder='DV'
                name='user_bank_info.agency_number_dv'
                disabled
              />
            </div>
            <Input
              placeholder='Conta'
              name='user_bank_info.account_number'
              disabled
            />
            <div style={{ width: 50, marginInline: 10 }}>
              <Input
                placeholder='DV'
                name='user_bank_info.account_number_dv'
                disabled
              />
            </div>
          </ContainerInputs>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                width: 300,
              }}
              loading={loading}
              type='submit'
            >
              {'Pagar'}
            </Button>
          </div>
        </Form>
      </Container>
    </ModalBase>
  );
};
