import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { MainContainer } from '../../components/MainContainer';
import { Textarea } from '../../components/Textarea';
import { Button } from '../../components/Button';
import { useToast } from '../../context/ToastContext';

import { editingTerms, listTerms } from '../../services/TermsUsePolicy';
import { Terms } from '../../services/TermsUsePolicy/types';

import { SubTitle, FormSettings } from './styles';
import { getFormError } from '../../util/formErrors';

export const GeneralSettings: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState<Terms | undefined>(undefined);
  const [isDiffData, setIsDiffData] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleListTerms = useCallback(async () => {
    try {
      const response = await listTerms();
      setTerms(response);
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Ocorreu um error',
        description: error?.data?.detail,
      });
    }
  }, []);

  const handleChangeFormData = useCallback(async () => {
    if (JSON.stringify(formRef.current?.getData()) !== JSON.stringify(terms))
      setIsDiffData(true);
    else setIsDiffData(false);
  }, [terms]);

  const handleSubmit = useCallback(
    async (data: Terms) => {
      setLoading(true);
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          terms_of_use: Yup.string().required('Campo obrigatório'),
          privacy_policy: Yup.string().required('Campo obrigatório'),
        });
        await schema.validate(data, { abortEarly: false });

        const response = await editingTerms(data);
        setTerms(response);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Termos Atualizados com sucesso!',
        });
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getFormError(error);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Ocorreu um error',
            description: error?.data?.detail,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast]
  );

  useEffect(() => {
    handleListTerms();
  }, [handleListTerms]);

  return (
    <MainContainer title='Configurações Gerais'>
      <SubTitle>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</SubTitle>
      <FormSettings
        ref={formRef}
        initialData={terms}
        onSubmit={handleSubmit}
        onChange={handleChangeFormData}
      >
        <Textarea name='terms_of_use' placeholder='Termos de Uso' />
        <Textarea name='privacy_policy' placeholder='Política de Uso' />
        <Button type='submit' disabled={!isDiffData} loading={loading}>
          SALVAR
        </Button>
      </FormSettings>
    </MainContainer>
  );
};
