import React, { useEffect } from 'react';
import { FiAlertCircle, FiXCircle } from 'react-icons/fi';
import { ToastMessage, useToast } from '../../../context/ToastContext';
import { Container } from './styles';

interface ContentProps {
  message: ToastMessage;
}

export const Content: React.FC<ContentProps> = ({ message }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => clearTimeout(timer);
  }, [removeToast, message.id]);

  return (
    <Container type={message.type}>
      <FiAlertCircle size={20} />
      <div>
        <strong>{message.title}</strong>
        <p>{message.description}</p>
      </div>
      <button onClick={() => removeToast(message.id)} type='button'>
        <FiXCircle size={18} />
      </button>
    </Container>
  );
};
