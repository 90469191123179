import { complement } from 'polished';
import { FC, ComponentType } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { UserConfirmation } from '../pages/UserConfirmation';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: ComponentType;
}
export const Route: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { logged } = useAuth();
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) =>
        isPrivate === logged || Component === UserConfirmation ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : 'indicators',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
