/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form } from '@unform/web';
import React from 'react';

import { formatDate } from '../../../../util/dateFormat';
import { Container, ContainerInputs } from './styles';
import { ModalBase, ModalBaseProps } from '../../';
import { Input } from '../../../Input';

interface Client {
  name: string;
  email: string;
  phone: string;
  cpf_cnpj: string;
}

interface ReportShowProps extends Omit<ModalBaseProps, 'title'> {
  client: any;
}

export const ReportShow: React.FC<ReportShowProps> = ({
  modalIsOpen,
  handleCloseModal,
  client,
}) => {
  return (
    <ModalBase
      modalIsOpen={modalIsOpen}
      handleCloseModal={handleCloseModal}
      title='RECICLAGENS'
    >
      <Container style={{ height: 600, overflowY: 'scroll', paddingRight: 10 }}>
        <Form onSubmit={() => {}} initialData={client}>
          <Input
            placeholder='Nome Completo'
            name='user_hashcod.name'
            disabled
          />

          <ContainerInputs style={{ justifyContent: 'flex-start', gap: 5 }}>
            <Input
              placeholder='CPF'
              name='user_hashcod.cpf_cnpj'
              disabled
              style={{ width: '100%' }}
            />
            <Input
              placeholder='Telefone'
              name='user_hashcod.phone'
              disabled
              style={{ width: '100%' }}
            />
          </ContainerInputs>

          <Input
            placeholder='E-mail'
            name='user_hashcod.email'
            disabled
            style={{ width: '100%' }}
          />
          <ContainerInputs>
            <div style={{ width: '100%' }}>
              <Input
                placeholder='Endereço Completo'
                name='full_address'
                disabled
              />
            </div>
          </ContainerInputs>
          <ContainerInputs style={{ justifyContent: 'flex-start', gap: 5 }}>
            <div style={{ width: '100%' }}>
              <Input placeholder='Referência' name='reference' disabled />
            </div>

            <div style={{ width: '50%' }}>
              <Input placeholder='Número' name='number' disabled />{' '}
            </div>
          </ContainerInputs>
          <ContainerInputs style={{ justifyContent: 'flex-start', gap: 5 }}>
            <Input
              placeholder='Tipo do Resíduo'
              name='waste_types_hashcod.name'
              disabled
            />
            <Input placeholder='Peso' name='waste_weight' disabled />
            <div>
              <div style={{ marginBottom: 7, color: '#707070' }}>
                Data da Coleta
              </div>
              <input
                style={{ height: 34, width: '100%' }}
                value={formatDate(client.recycling_day_time)}
                disabled
              />
            </div>
          </ContainerInputs>
          <Input
            placeholder='Colaborador'
            name='collaborator_hashcod.name'
            disabled
          />
        </Form>
      </Container>
    </ModalBase>
  );
};
