import styled from 'styled-components';
import { Form } from '@unform/web';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  color: var(--prussian-blue);
`;

export const SubTitle = styled.h2`
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--dove-gray);
`;

export const FormSettings = styled(Form)`
  display: flex;

  flex-direction: column;
  align-items: center;
  button {
    margin-top: 100px;
    width: 374px;
  }
`;
