import axios from 'axios';

import { API_URL } from '../config';

import * as AuthService from './Auth';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  async function (config) {
    const storagedToken = localStorage.getItem('@token');

    if (storagedToken) {
      config.headers.Authorization = `JWT ${storagedToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { api, AuthService };
