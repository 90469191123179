import React, { useCallback, useEffect, useState } from 'react';
import { HiXCircle } from 'react-icons/hi';
import { RiPencilFill } from 'react-icons/ri';

import { MainContainer } from '../../components/MainContainer';
import { WasteDelete } from '../../components/Modals/WasteDelete';
import { WasteRegistry } from '../../components/Modals/WasteRegistry';

import { useToast } from '../../context/ToastContext';

import { listWaste } from '../../services/Waste';
import { ListWasteResponseProps } from '../../services/Waste/types';

import { format } from '../../util/format';

import { Table, TableButton } from './styles';

export const Wastes: React.FC = () => {
  const [showModalWasteRegistry, setShowModalWasteRegistry] = useState(false);
  const [showModalWasteDelete, setShowModalWasteDelete] = useState(false);
  const [wastes, setWastes] = useState<ListWasteResponseProps[]>([]);
  const [wastSelected, setWasteSelected] = useState<ListWasteResponseProps>(
    {} as ListWasteResponseProps
  );
  const [loadingWastes, setLoadingWastes] = useState(true);
  const { addToast } = useToast();

  useEffect(() => {
    handleListWaste();
  }, []);

  const handleListWaste = async () => {
    try {
      const response = await listWaste();

      setWastes(() => [...response]);
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Ocorreu um error',
        description: error?.data?.detail,
      });
    } finally {
      setLoadingWastes(false);
    }
  };

  const handleCloseModalWasteRegistry = useCallback(() => {
    setShowModalWasteRegistry(false);
    setWasteSelected({} as ListWasteResponseProps);
  }, []);

  const handleOpenModalWasteRegistry = useCallback(
    (waste?: ListWasteResponseProps) => {
      setShowModalWasteRegistry(true);
      if (waste) setWasteSelected(waste);
    },
    []
  );

  const handleCloseModalWasteDelete = useCallback(() => {
    setShowModalWasteDelete(false);
    setWasteSelected({} as ListWasteResponseProps);
  }, []);

  const handleOpenModalWasteDelete = useCallback(
    (waste: ListWasteResponseProps) => {
      setShowModalWasteDelete(true);
      setWasteSelected(waste);
    },
    []
  );

  const handleDeletedItem = useCallback((hashcod: string) => {
    setWastes(oldWastes =>
      oldWastes.filter(wastes => wastes.hashcod !== hashcod)
    );
  }, []);

  const handleShowInitials = useCallback((days: any) => {
    return days.map((day: string) => day.substr(0, 3).toUpperCase()).toString();
  }, []);
  // button={{title:'Cadastro de Residuos',onClick={() => handleOpenModalWasteRegistry()}
  return (
    <MainContainer
      title='Cadastro de Resíduos'
      button={{
        title: 'Cadastrar Resíduo',
        onClick: () => handleOpenModalWasteRegistry(),
      }}
    >
      <Table>
        <thead>
          <tr>
            <th>RESÍDUO</th>
            <th>DIA DA COLETA</th>
            <th>VALOR (KG)</th>
            <th>EDITAR</th>
            <th>EXCLUIR</th>
          </tr>
        </thead>
        {loadingWastes ? (
          <></>
        ) : (
          <tbody>
            {wastes.map(waste => (
              <tr key={waste.hashcod}>
                <td>{waste.name}</td>
                <td>{handleShowInitials(waste.days_week)}</td>
                <td>{format('CURRENCY', Number(waste.price_per_kilo))}</td>
                <td>
                  <TableButton
                    onClick={() => handleOpenModalWasteRegistry(waste)}
                  >
                    <RiPencilFill size={22} color='var(--fern)' />
                  </TableButton>
                </td>
                <td>
                  <TableButton
                    onClick={() => handleOpenModalWasteDelete(waste)}
                  >
                    <HiXCircle size={22} color='var(--red)' />
                  </TableButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>

      <WasteRegistry
        modalIsOpen={showModalWasteRegistry}
        handleCloseModal={handleCloseModalWasteRegistry}
        waste={wastSelected}
        isSuccessful={() => handleListWaste()}
      />
      <WasteDelete
        waste={wastSelected}
        modalIsOpen={showModalWasteDelete}
        handleCloseModal={handleCloseModalWasteDelete}
        hasDeleted={hashcod => handleDeletedItem(hashcod)}
      />
    </MainContainer>
  );
};
