import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--blue-stone);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 500px;
  height: 500px;
  border-radius: 20px;
  background: var(--white);
  .isoLogoWrapper {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;

    a {
      font-size: 24px;
      font-weight: 300;
      line-height: 1;
      text-transform: uppercase;

      img {
        display: flex;
        align-self: center;
      }
      h2 {
        display: flex;
        align-self: center;
      }
    }
  }
`;

export const ConfirmationText = styled.h3`
  text-align: center;
`;
