import { Card, Container } from './styles';

interface IndicatorProps {
  title?: string;
  cardTitle: string;
  cardSubtitle: string | number;
}

export function Indicator({ title, cardTitle, cardSubtitle }: IndicatorProps) {
  return (
    <Container>
      <h2>{title}</h2>

      <Card>
        <label>{cardTitle}</label>

        <span>{cardSubtitle}</span>
      </Card>
    </Container>
  );
}
