import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  TextareaHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  hidden?: boolean;
}
export const Textarea: React.FC<TextareaProps> = ({
  name,
  hidden,
  ...rest
}) => {
  const TextareaRef = useRef(null);
  const { registerField, fieldName, defaultValue, error } = useField(name);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: TextareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  return (
    <Container hidden={hidden} isErrored={!!error} isFocused={isFocused}>
      <label>{rest.placeholder}</label>
      <textarea
        autoComplete='false'
        onFocus={handleFocus}
        onBlur={handleBlur}
        defaultValue={defaultValue}
        ref={TextareaRef}
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
};
