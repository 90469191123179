import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { api, AuthService } from '../services';

import { SignInRequest } from '../services/Auth/types';

interface AuthState {
  token: string;
  userType: string | null;
}

interface AuthContextData {
  logged: boolean;
  signIn: (data: SignInRequest) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@token');
    const userType = localStorage.getItem('@userType');
    if (token) return { token, userType };
    return {} as AuthState;
  });

  useEffect(() => {
    function loadStorageData() {
      if (data.token) api.defaults.headers.Authorization = `JWT ${data.token}`;
    }

    loadStorageData();
  }, [data]);

  const signIn = useCallback(async (data: SignInRequest) => {
    try {
      const { token, user_type } = await AuthService.signIn(data);
      api.defaults.headers.Authorization = `JWT ${token}`;
      localStorage.setItem('@token', token);
      localStorage.setItem('@userType', user_type);
      setData({ token, userType: user_type });
    } catch (error) {
      throw new Error(
        error.data?.non_field_errors[0] ? 'INVALID_CREDENTIALS' : error?.data[0]
      );
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@token');
    localStorage.removeItem('@userType');
    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ logged: !!data.token, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

export { AuthContext, AuthProvider, useAuth };
